.CookieConsent {
  border-radius: 10px 10px 0 0;
  box-shadow: var(--box-shadow);

  @media only screen and (max-width: 600px) {
    padding-bottom: 1rem;

    button {
      width: 100% !important;
      margin: 0 !important;
      padding: 0.8rem !important;
    }

    div {
      width: 100% !important;
      margin: 0 50px;
      text-align: center;
    }
  }
}
